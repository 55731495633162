/*@font-face {*/
/*    font-family: MondayMadnessFont-outline;*/
/*    src: url('./assets/MondayMadnessFont-Outline-Regular.ttf');*/
/*}*/

@font-face {
    font-family: MondayMadnessFont-fill;
    /* src: url('./assets/fonts/MondayMadnessFont-Filled-Regular.ttf'); */
    src: url('./assets/fonts/SWEETGIRL.ttf'); 

}

/*@font-face {*/
/*    font-family: TheSideStream;*/
/*    src: url('./assets/The\ Sidestream.ttf');*/
/*}*/

@font-face {
    font-family: RamblaBold;
    src: url('./assets/fonts/Rambla-Bold.ttf');
}

iframe {
    pointer-events: none;
}
