/*@tailwind base;*/
@tailwind components;
@tailwind utilities;
:root {
    --link-color:#337ab7;
    --main-color:#eab918;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    background-image: linear-gradient(#55aee3,#4a95c1,#3e7494, #12232e)   !important ;
    background-size: 100% 400% !important;
    background-repeat: no-repeat !important ;;


}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: #337ab7;
    text-decoration: none;
}
button {
    margin-top: -20px;
    display: inline-block;
    font-family: RamblaBold, serif;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 2px solid #000;
    padding: 0.35rem 0.75rem;
    font-weight: 700;
    font-size: 23px;
    border-radius: 25px;
    color: #fff;
    text-shadow: 2px 0 0 #000, -2px 0 0 #000, 0 2px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
